import React, { Component } from "react";
import {
  StyleSheet,
  Dimensions,
  View,
  Text,
  TouchableHighlight,
  Image,
  ProgressViewIOS,
  Alert,
  Platform,
  TouchableOpacity
} from "react-native";
import { connect } from "react-redux";
// import ImagePicker from 'react-native-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Lightbox from "react-native-lightbox";
import { getApiPath, uploadMedia } from "../../api";
import * as Permissions from "expo-permissions";
import colors from "../../constants/colors";
import alerts from "../components/alert";
import NewIssue from "../dialogs/newIssue";
import Swal from "sweetalert2";
export default class Document extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      documentText: this.props.placeholder,
      documentPath:
        typeof this.props.initial === "object"
          ? this.props.initial
          : "selected",
      documentState: this.props.initial ? "selected":"select",
      ignoreInitial: false,
      documentName:false,
      dialogVisible: false,
    };
  }

  pickDocument =  async () => {
    let result = await DocumentPicker.getDocumentAsync({
      type: '*/*'
    });
    if(result.type=="success"){
    this.setState({documentName:result.name})  
     this.uploadDocument(result.uri, result.name); 
        
    }
    
    
  };


  uploadDocument(uri, name) {

    var ext=name.split('.').pop();
    var forbiddenExt = ["txt", "pdf", "doc", "docx", "csv", 'xls', 'xlsx', "html", 'xml', 'pptx', 'ppt', "odt", 'ods'];
    var n = forbiddenExt.includes(ext);
    if(!n){
      Swal.fire({
        text: "File is not permitted",
        confirmButtonText: "OK",
        confirmButtonColor: "#F4981E",
        backdrop: false

      });
      return;
    }
    if(Platform.OS=='ios'){
      this.setState({ documentPath: uri.replace("file://", "") });
    }else{
      this.setState({ documentPath: uri });
    }
    this.updateDocument(uri, name);
    return;
  }

  updateDocument(uri, name) {
    const { id } = this.props;
    let data='document';
    this.props.onChange(id, { data, uri, name });

    this.setState({
      documentState: "selected",
    });
  }

  removeDocument() {
    Swal.fire({
      title: "Remove Document",
      text: "If you leave this form your data will be lost. Are you sure?",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "OK",
      confirmButtonColor: "#F4981E",
      backdrop: false

    }).then((result) => {
      if(result.isConfirmed) {
        this.setState({
          imageId: 0,
          uploadProgress: 0,
          documentState: "select", // select / uploading / selected
          documentText: "No Document added",
          imagePath: null,
          ignoreInitial: true,
          documentName: false,
        });
        this.props.onChange(this.props.id, 0);
      }
    });
  }

  render() {    
    const {ignoreInitial} = this.state; 
    const { initial, label, onChange, id, isIssuable, onSubmitIssue, isCompleted } = this.props;
    console.log(JSON.stringify(initial))
    return (
      <>
      <NewIssue 
        visible={this.state.dialogVisible} 
        id={id} 
        label={label} 
        onSubmitIssue={onSubmitIssue} 
        handleClose={()=>this.setState({dialogVisible:false})} 
        />

      <View style={styles.container}>
        <Text style={styles.label2} >{label}</Text>  
        {
          (isIssuable && isCompleted) && 
          <TouchableOpacity style={{paddingBottom:10, paddingTop:5}} onPress={() => {
            this.setState({dialogVisible:true});
          }}>
            <Text style={{color:'#002F6C', fontWeight:'bold'}}>Issue</Text>  
          </TouchableOpacity>
        }
 
        {this.state.documentState == "selected" ? (
          <View style={{ flex: 1, flexDirection: "row" }}>
            <View style={styles.wrapperUploaded}>
              {initial !== undefined?  
              <Text style={styles.label}>{this.state.documentName?`Document: ${this.state.documentName}`:`Document: ${initial.name}`}</Text>
              :
              <Text style={styles.label}>{`Document: ${this.state.documentName}`}</Text>
              }
            </View>
            <TouchableHighlight
              style={{
                position: "absolute",
                top: 20,
                right: 20,
                backgroundColor: "white",
                height: 40,
                width: 40,
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 20,
              }}
              onPress={() => {
                this.removeDocument();
              }}
            >
              <MaterialCommunityIcons
                name="delete"
                size={28}
                color="red"
                style={styles.icon}
              />
            </TouchableHighlight>
          </View>
        ) : (
          <View style={styles.wrapperInitial}>
            <TouchableHighlight
              style={styles.button}
              underlayColor={colors.secondary}
              onPress={this.pickDocument}
            >
              <Text style={styles.buttonText}>Add Document</Text>
            </TouchableHighlight>
            <Text style={styles.label}>{this.state.documentText}</Text>
          </View>
        )}
      </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    paddingTop: 5,
    paddingHorizontal: 10,
    paddingBottom: 10,
  },
  wrapperInitial: {
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#C8C9CB",
    borderRadius: 5,
  },
  wrapperUploaded: {
    flexDirection: "row",
    alignItems: "flex-start",
    padding: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#C8C9CB",
    borderRadius: 5,
  },
  button: {
    backgroundColor: colors.primary,
    borderRadius: 5,
    height: 40,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
    overflow: "hidden",
  },
  buttonText: {
    color: "#fff",
    fontSize: 18,
    fontWeight: "300",
    fontFamily: "Roboto",
  },
  label: {
    fontSize: 16,
    fontWeight: "300",
    marginLeft: 10,
    fontFamily: "Roboto",
    fontStyle: "italic",
    color: "#424242",
  },
  image: {
    maxHeight: 80,
  },
  label2: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',

  },
});
