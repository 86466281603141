import { createAction } from '@reduxjs/toolkit'
import {
  SUBMISSION_SUCCESS,
  SUBMISSION_ERROR,
  SUBMISSION_START,
  SUBMISSION_PICTURE_START,
  SUBMISSION_PICTURE_SUCCESS,
  SUBMISSION_PICTURE_ERROR,
  SUBMISSION_DOCUMENT_START,
  SUBMISSION_DOCUMENT_SUCCESS,
  SUBMISSION_DOCUMENT_ERROR,
  SUBMISSION_FORM_START,
  SUBMISSION_FORM_SUCCESS,
  SUBMISSION_FORM_ERROR,
  SUBMISSION_STARTING,
  SUBMISSION_FINISHED,
  LOG_DIALOG_OPEN,
  LOG_DIALOG_CLOSE,
  SET_SUBMISSION_SELECTED,
  SET_IS_SUBMISSION,
  BIG_FILE_LOADING,
  LOADING_ISSUE
} from "../constants/actions";
import { getTasks } from "../actions/tasks"
import { Alert, Platform } from "react-native";
import uuid from "uuid";
import when from "when";
import { uploadMediaB, sendForm, submitIssue, insertBase64, syncErrorUpload, uploadDocument, uploadMedia, setFileToFormTemplate, triggerTask } from "../api";
import Moment from "moment";
import AppJson from "../../app.json";
import { getForm } from "./menu";
import { getAllRecords } from './records';
import { getForm as _getForm } from "../actions/menu";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import { getLogGroupsSubAction } from './loggroups';

const validFilledField = (type, value) => {
  switch (type) {
    case "yesno":
      return value ? value : false;
    case "inventory":
      return (value?.lotcode != "" && value?.productname != "" && value?.quantity != "");
    case "signature":
      return value !== 0;
    default:
      return (value != "" && typeof value !== 'undefined' && value !== 0)
  }
}

export function sync() {
  return (dispatch, getState) => {
    const submissions = getState().submissions.get("submissions");
    let promises = [];
    let syncErrorAlerted = false;
    let objectIdTriggerable = null;
    submissions.forEach((s) => {
      const components = [...s.components];
      const nComponents = []; // becasuse we cannot mutate an state on dispatch , we create a new version of components and send them to the request
      const choicelist = s.choicelist;
      const logroup = s.logroup;
      let submission = { ...s.submission };
      const task_id = s.task_id;

      // if (s.status !== "pending") return;

      // Temporary fix for old reports submitted with version 1.2.0
      if (!components || !choicelist) {
        dispatch(createAction(SUBMISSION_SUCCESS)({ uuid: s.uuid }));
        return;
      }
      let date = "?-?";
      
      if (components[1]&&components[1]["type"] == "datetime") {
        date = Moment(submission[components[1]["id"]]).format("MM-DD");
      }


      promises.push({
        t: "starting",
        f: (res) => {
          dispatch(
            createAction(SUBMISSION_START)({
              uuid: s.uuid,
              report: s.report,
              date,
            })
          );
        },
      });

      if (Platform.OS === 'web') {
        if (components.length > 0) {

          components.forEach((comp) => {
            const c = { ...comp } // becasuse we cannot mutate an state on dispatch , we create a new version of component and push it to nComponents


            if (typeof c?.triggerable !== 'undefined') {
              if (c?.triggerable && !c?.triggered && validFilledField(c.type, submission[c.id])) {
                objectIdTriggerable = c?.triggerForm;
              }
            }
            // console.log({c, sub:submission[c.id]});
            switch (c.type) {
              case "picture":
                if (submission[c.id] != 0 && typeof submission[c.id] !== undefined) {
                  const val = submission[c.id];
                  if (val.includes("data:")) {
                    promises.push({
                      t: "picture",
                      f: () => {
                        dispatch(
                          createAction(SUBMISSION_PICTURE_START)({ uuid: s.uuid })
                        );
                        return uploadMedia(
                          dispatch,
                          getState,
                          null,
                          submission[c.id],
                          ".png",
                        )
                          .then((res) => {
                            let data = res.data;
                            submission[c.id] = data.path;
                            dispatch(
                              createAction(SUBMISSION_PICTURE_SUCCESS)({ uuid: s.uuid })
                            );
                          })
                          .catch((err) => {
                            dispatch(
                              createAction(SUBMISSION_PICTURE_ERROR)({ uuid: s.uuid })
                            );
                            throw err;
                          });
                      },
                    });
                  }
                  else {
                    // submission[c.id] = 0;
                  }
                }
                else {
                  // submission[c.id] = 0;
                }
                break;
              case "document":
                if (typeof submission[c.id] === "object") {

                  promises.push({
                    t: "document",
                    f: () => {
                      dispatch(
                        createAction(SUBMISSION_DOCUMENT_START)({ uuid: s.uuid })
                      );

                      if (!submission[c.id]["path"]) {
                        return uploadMedia(
                          dispatch,
                          getState,
                          submission[c.id]["data"],
                          submission[c.id]["uri"],
                          submission[c.id]["name"],
                        )
                          .then((res) => {
                            let data = res.data;
                            data.name = submission[c.id]["name"];
                            submission[c.id] = {
                              ...data,
                            };
                            dispatch(
                              createAction(SUBMISSION_DOCUMENT_SUCCESS)({ uuid: s.uuid })
                            );
                          })
                          .catch((err) => {
                            dispatch(
                              createAction(SUBMISSION_DOCUMENT_ERROR)({ uuid: s.uuid })
                            );
                            throw err;
                          });
                      }
                    },
                  });
                }
                else {
                  submission[c.id] = 0;
                }
                console.log(JSON.stringify(submission[c.id]))
                break;
              case "signature":
                if (submission[c.id] != 0) {
                  insertBase64(dispatch, getState, submission[c.id]);
                }
                break;
            }
            nComponents.push(c);
          });
        }
      } else {
        if (components.length > 0) {

          components.forEach((c) => {

            // Upload pictures
            if (c.type === "picture" && typeof submission[c.id] === "object") {
              // Submit image
              promises.push({
                t: "picture",
                f: () => {
                  dispatch(
                    createAction(SUBMISSION_PICTURE_START)({ uuid: s.uuid })
                  );
                  return uploadMediaB(
                    dispatch,
                    getState,
                    submission[c.id]["data"],
                    submission[c.id]["uri"]
                  )
                    .then((res) => {
                      let data = res.data;
                      submission[c.id] = data.path;
                      dispatch(
                        createAction(SUBMISSION_PICTURE_SUCCESS)({ uuid: s.uuid })
                      );
                    })
                    .catch((err) => {
                      dispatch(
                        createAction(SUBMISSION_PICTURE_ERROR)({ uuid: s.uuid })
                      );
                      throw err;
                    });
                },
              });
            }
            //for documents
            if (c.type === "document" && submission[c.id] !== undefined) {
              console.log('DOC ' + JSON.stringify(submission[c.id]))
              if (submission[c.id]["uri"] && submission[c.id]["uri"].includes('data:application')) {
                promises.push({

                  t: "document",
                  f: () => {
                    dispatch(
                      createAction(SUBMISSION_DOCUMENT_START)({ uuid: s.uuid })
                    );
                    let data = {};
                    data.uri = submission[c.id]["uri"];
                    data.name = submission[c.id]["name"];
                    submission[c.id] = data;
                    dispatch(
                      createAction(SUBMISSION_DOCUMENT_SUCCESS)({ uuid: s.uuid })
                    );
                  },
                })
              }
            }

            if (c.type === "section" && typeof submission[c.id] === "object") {
              if (submission[c.id]?.send) {
                promises.push({
                  t: "document",
                  f: () => {
                    dispatch(
                      createAction(SUBMISSION_DOCUMENT_START)({ uuid: s.uuid })
                    );
                    return uploadMedia(
                      dispatch,
                      getState,
                      submission[c.id]["data"],
                      submission[c.id]["uri"]
                    )
                      .then((res) => {
                        let data = res.data;
                        data.name = submission[c.id]["name"];
                        submission[c.id] = {
                          ...res.data,
                          status: 'uploaded',
                          type: 'media'
                        };

                        dispatch(
                          createAction(SUBMISSION_DOCUMENT_SUCCESS)({ uuid: s.uuid })
                        );
                      })
                      .catch((err) => {
                        dispatch(
                          createAction(SUBMISSION_DOCUMENT_ERROR)({ uuid: s.uuid })
                        );
                        throw err;
                      });
                  },
                });
              }
            }

            if (c.type === "document" && typeof submission[c.id] === "object" && !submission[c.id].path) {
              console.log('LLEGA AL DOC')
              // Submit document
              promises.push({
                t: "document",
                f: () => {
                  dispatch(
                    createAction(SUBMISSION_DOCUMENT_START)({ uuid: s.uuid })
                  );
                  return uploadDocument(
                    dispatch,
                    getState,
                    submission[c.id]["data"],
                    submission[c.id]["uri"]
                  )
                    .then((res) => {
                      let data = res.data;
                      data.name = submission[c.id]["name"];
                      submission[c.id] = data;

                      dispatch(
                        createAction(SUBMISSION_DOCUMENT_SUCCESS)({ uuid: s.uuid })
                      );
                    })
                    .catch((err) => {
                      dispatch(
                        createAction(SUBMISSION_DOCUMENT_ERROR)({ uuid: s.uuid })
                      );
                      throw err;
                    });
                },
              });
            }

            if (c.type === "signature") {
              // we do not need to call this endpoint with a promise, bc we only need to register the image
              insertBase64(dispatch, getState, submission[c.id]);
            }
          });
        }
      }

      // promises.push({
      //         t: 'error',
      //         f: () => {
      //             dispatch(createAction(SUBMISSION_ERROR)({uuid: s.uuid}));
      //             throw err;
      //             }
      //     });

      // Upload report
      promises.push({
        t: "report",
        f: () => {
          dispatch(createAction(SUBMISSION_FORM_START)({ uuid: s.uuid }));
          return sendForm(
            dispatch,
            getState,
            {
              components: nComponents,
              choicelist,
              submission,
              logroup: s.logroup,
              uuid: s.uuid,
              client: AppJson.expo.version,
              task_id,
              status: s.status ? s.status : "in_progress"            },
            s.id
          )
            .then((res) => {
              dispatch(createAction(SUBMISSION_FORM_SUCCESS)({ uuid: s.uuid }));
              dispatch(getTasks());
            })
            .catch((err) => {
              if (!syncErrorAlerted) {
                setTimeout(function () {
                  Swal.fire({
                    title: "Sync error",
                    text: "It seems you are having issues while syncing a report, we have already alerted our team to review it, if the problem persist please contact support.",
                    confirmButtonText: "Close",
                    confirmButtonColor: "#F4981E",
                    backdrop: false
                  });
                }, 5000);
                syncErrorAlerted = true;
              }
              syncErrorUpload(
                dispatch,
                getState,
                {
                  body: {
                    client: AppJson.expo.version,
                    error: err,
                    report: {
                      components: nComponents,
                      choicelist,
                      submission,
                      logroup: s.logroup,
                      uuid: s.uuid
                    }
                  }
                });
              dispatch(createAction(SUBMISSION_FORM_ERROR)({ uuid: s.uuid }));
              throw err;
            });
        },
      });

      // Update report status
      promises.push({
        t: "success",
        f: () => {
          dispatch(createAction(SUBMISSION_SUCCESS)({ uuid: s.uuid }));
        },
      });

      // Catch error - uploading image or form
      promises.push({
        t: "error",
        f: () => {
          dispatch(createAction(SUBMISSION_ERROR)({ uuid: s.uuid }));
          //throw err;
        },
      });
    });

    // Execute promises sequentially w/ 100ms delay
    promises
      .reduce((p, fn) => {
        return fn.t == "error"
          ? p.catch(fn.f).delay(100)
          : p.then(fn.f).delay(100);
      }, when())
      .then((res) => {
        dispatch(createAction(SUBMISSION_FINISHED)());
        Swal.fire({
          title: "Report Sent",
          text: "The report was sent",
          confirmButtonText: "Close",
          confirmButtonColor: "#F4981E",
          backdrop: false,
          willClose: () => {
            if(objectIdTriggerable){
              window.location.replace(window.location.origin + "/?id=" + objectIdTriggerable);
            }else{
              window.location.reload();
            }
          }
        });

        //To reload entries when a submission is added
        const taskSelected = getState().tasks.get("taskSelected");
        if(taskSelected?._id){
          dispatch(getLogGroupsSubAction(taskSelected?._id));
        }
        return res;
      });
  };
}

export function setAttachment({ data, uri, name, formId, val, componentId, mediaId, attachment_type, ...rest }) {
  console.log(attachment_type);
  if (attachment_type == 'note') {
    return (dispatch, getState) => {
      setFileToFormTemplate(dispatch, getState, {
        formId,
        componentId,
        attachment_type: 'note',
        path: val,
        attachmentId: mediaId || `${(new Date()).getTime()}`,
      })
        .then(_ => dispatch(_getForm(formId)));
    }
  } else if (attachment_type == 'file') {
    return (dispatch, getState) => {
      uploadMedia(
        dispatch,
        getState,
        data,
        uri,
        name,
      )
        .then((res) => {
          setFileToFormTemplate(dispatch, getState, {
            formId,
            componentId,
            path: res.data.path,
            attachment_type: 'file',
            attachmentId: mediaId || `${(new Date()).getTime()}`,
          })
            .then(_ => {
              dispatch(createAction(BIG_FILE_LOADING)('loaded'))
              dispatch(_getForm(formId))
            });
        });
    }
  }
}

export function setBigFileIsLoading(payload) {
  return(dispatch,getState) => {
    dispatch(createAction(BIG_FILE_LOADING)(payload))
  }
}

export function startingSync() {
  return (dispatch, getState) => {
    console.log('startingSync')
    dispatch(createAction(SUBMISSION_STARTING)());
  };
}

export function logDialog() {
  return (dispatch, getState) => {
    dispatch(createAction(LOG_DIALOG_OPEN)());
  };
}

export function logDialogClose() {
  return (dispatch, getState) => {
    dispatch(createAction(LOG_DIALOG_CLOSE)());
  };
}
export function setSubmissionSelected(submission) {
  return (dispatch, getState) => {
    dispatch(createAction(SET_SUBMISSION_SELECTED)(submission));
  };
}
export function setIsSubmission(value) {
  return (dispatch, getState) => {
    console.log('VALUE' + value)
    dispatch(createAction(SET_IS_SUBMISSION)(value));
  };
}
export function loadingIssue(value) {
  return (dispatch, getState) => {
    dispatch(createAction(LOADING_ISSUE)(value));
  };
}

export function sendSubmit(submissionId, submissions, IssueSolved, callback = false) {
  return (dispatch, getState) => {
    submitIssue(dispatch, getState, submissionId, submissions, IssueSolved)
      .then(response => {
        dispatch(setSubmissionSelected(response));
        if (callback) callback(); // we expect the forceUpdate fuction so it could load issues
      });
  }
}
