import React, { Component } from "react";
import { StyleSheet, View, TextInput, Text, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import NewIssue from "../dialogs/newIssue";

export default class InputWithLabel extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
    value: this.props.initial,
    dialogVisible: false,
    height: 30,

    };
  }

  


  render() {
    const { value } = this.state;
    const { id, onChange, label, isIssuable, onSubmitIssue, isCompleted } = this.props;
    const warningBackground = { background: '#ef6c00' };

    return (
      <>
        <NewIssue
          visible={this.state.dialogVisible}
          id={id}
          label={label}
          onSubmitIssue={onSubmitIssue}
          handleClose={() => this.setState({ dialogVisible: false })}
        />

        <View style={styles.container}>
          <Text style={styles.label}>{label}</Text>
          {
            (isIssuable && isCompleted) &&
            <TouchableOpacity style={{ paddingBottom: 10, paddingTop: 5 }} onPress={() => {
              this.setState({ dialogVisible: true });
            }}>
              <Text style={{ color: '#002F6C', fontWeight: 'bold' }}>Issue</Text>
            </TouchableOpacity>
          }
          <TextInput
            value={value}
            onContentSizeChange={(event) => {
              this.setState({ height: event.nativeEvent.contentSize.height });
            }}
            style={styles.input, {
              borderColor: "#e7e7e7",
              borderRadius: 5,
              borderWidth: 1,
              color: "#424242",
              fontFamily: "Roboto",
              fontSize: 20,
              fontWeight: "400",
              padding: 5, height: this.state.height,
              fontStyle: this.state.value.length == 0 ? 'italic' : 'normal'
            }}
            multiline={true}
            placeholder={this.props.placeholder}
            placeholderTextColor={'rgb(0, 47, 108)'}
            onChangeText={(val) => {
              if(val == '' && this.props.placeholder == ''){
                this.setState({ value: val, height: 30 });

              }else{
                this.setState({ value: val });

              }
              onChange(id, val);

            }}
          />
          

        </View>

      </>

    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    paddingVertical: 15,
    paddingHorizontal: 10,
  },
  input: {
    fontSize: 22,
    color: "#424242",
    fontWeight: "400",
    height: 60,
    flex: 1,
    borderColor: "#e7e7e7",
    borderWidth: 1,
    borderRadius: 5,
    paddingLeft: 10,
    fontFamily: "Roboto",
  },
  label: {
    fontSize: 20,
    color: '#939598',
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
  error: {
    backgroundColor: 'rgb(255, 192, 203)',
    color: '#fff',
    padding: '4px',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px'
  }
});
